import React from "react"
import ContainerMain from "src/pages/common/ContainerMain"
import FilterJobs from "./FilterJobs"
import "./JobOpening.scss"
import { useFormatter } from "helpers/i18n"

export default function JobOpening({ data }) {
  const { __ } = useFormatter()
  return (
    <>
      <div className="TgCFPpSuJm">
        <ContainerMain>
          <div className="job-openning-border">
            <div className="title">
              <p className="short-description">
                {__({
                  defaultMessage:
                    "Join us in creating impact that matters. Shape the future and start your growth journey with us!",
                })}
              </p>
              <p className="section-title name">
                {__({
                  defaultMessage: "Job Opening",
                })}
              </p>
            </div>
            <FilterJobs data={data} is_tech={true} />
          </div>
        </ContainerMain>
      </div>
    </>
  )
}
